<template>
  <v-container class="justify-center">
    <v-row justify="center">
      <MultiChoiceBlankFill :inPlaceReplacementChar="'_'" :componentData="$store.state.fillBlanksColors"/>
    </v-row>
  </v-container>
</template>
<script>
import MultiChoiceBlankFill from './MultiChoiceBlankFill'
export default {
  name: 'FillBlanksColors',
  components: {
    MultiChoiceBlankFill
  }
}
</script>
